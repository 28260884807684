import React, { createContext, useCallback, useEffect, useState } from 'react'
import { script } from '../requests/fileResource'
const stub = () => {
  throw new Error('forgot to wrap provider')
}

const initState = {
  createPartsElement: stub,
  elements: null,
  submit: stub,
}
export const PaymentContext = createContext(initState)
export const PaymentConsumer = PaymentContext.Consumer

export const PaymentProvider = ({ children }) => {
  const [payjp, setPayjp] = useState(null)
  const [elements, setElements] = useState(null)

  useEffect(() => {
    const init = async () => {
      // await script('https://js.pay.jp/v2/pay.js')
      // const payjp = window.Payjp(process.env.REACT_APP_PAYJP_PUBLIC_KEY)
      // setPayjp(payjp)
      // const elements = payjp.elements()
      // setElements(elements)
    }
    init()
  }, [])

  const submit = async (numberElement) => {
    const res = await payjp.createToken(numberElement)
    const token = res.id
    console.log(token)
    return res.id
  }

  const createPartsElement = useCallback(() => {
    return {
      number: elements.create('cardNumber'),
      expiry: elements.create('cardExpiry'),
      cvc: elements.create('cardCvc'),
    }
  }, [elements])

  return (
    <PaymentContext.Provider value={{ createPartsElement, elements, submit }}>
      {children}
    </PaymentContext.Provider>
  )
}
