import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import useRequest from '@hooks/useRequest'
import moment from 'moment'
import hash from 'object-hash'

//const stub = () => {
//throw new Error('forgot to wrap provider')
//}

const initialState = {
  groups: null,
  unReadGroups: null,
}

export const ChatContext = createContext(initialState)
const { Provider } = ChatContext

export const ChatProvider = ({ children }) => {
  const [groups, setGroups] = useState([])
  const [unReadGroups, setUnReadGroups] = useState([])
  const groupRequest = useRequest('/v1/chats/groups')
  const groupRequestRef = useRef(() => {}, [])

  // 新着ルームの比較
  const updateGroups = (newGroups) => {
    //未読グループ
    const newUnReadGroups = newGroups.filter((nG) =>
      moment(nG.readAt).isBefore(nG.updatedAt)
    )
    if (hash(groups) !== hash(newGroups)) {
      setGroups(newGroups)
    }
    if (hash(unReadGroups) !== hash(newUnReadGroups)) {
      setUnReadGroups(newUnReadGroups)
    }
  }

  //group 取得callbackの定義
  groupRequestRef.current = async () => {
    const res = await groupRequest.get({ limit: 1000, page: 1 })
    const sortedGroups = res.data.sort((a, b) =>
      moment(b.updatedAt).diff(a.updatedAt)
    )
    updateGroups(sortedGroups)
  }

  //roomの定期取得
  useEffect(() => {
    const id = setInterval(() => {
      getGroups()
    }, 5000)
    return () => clearInterval(id)
  }, [])

  const getGroups = () => {
    groupRequestRef.current && groupRequestRef.current()
  }

  return (
    <Provider
      value={{
        unReadGroups,
        groups,
        getGroups,
      }}
    >
      {children}
    </Provider>
  )
}
