import { navigationDisplayRoutes as routes } from '@routes'
import React, { createContext, useState } from 'react'
const stub = () => {
  throw new Error('forgot to wrap provider')
}

const initState = {
  initMenu: stub,
  changeActiveMenuFromLocation: stub,
  menuItems: null,
  activatedMenuItemIds: null,
}
export const AppMenuContext = createContext(initState)
export const AppMenuConsumer = AppMenuContext.Consumer

export const AppMenuProvider = ({ children }) => {
  const [menuItems, setMenuItems] = useState(null)
  const [activatedMenuItemIds, setActivatedMenuItemIds] = useState(null)
  const initMenu = () => {
    const menuItems = assignIdAndParent(routes)
    setMenuItems(menuItems)
  }
  const changeActiveMenuFromLocation = () => {
    const menuItems = assignIdAndParent(routes)
    const activatedMenuItemIds = getActivatedMenuItemIds(menuItems)
    setActivatedMenuItemIds(activatedMenuItemIds)
  }

  return (
    <AppMenuContext.Provider
      value={{
        initMenu,
        changeActiveMenuFromLocation,
        menuItems,
        activatedMenuItemIds,
      }}
    >
      {children}
    </AppMenuContext.Provider>
  )
}
let uuid = 1

const assignIdAndParent = (menuItems, parentId) => {
  menuItems = menuItems || []
  menuItems.forEach((item) => {
    const id = item.id || uuid
    uuid += 1
    item.id = id
    item.parentId = item.parentId || parentId
    item.active = false

    if (typeof item.children !== 'undefined') {
      assignIdAndParent(item.children, id)
    }
  })
  return menuItems
}

const getActivatedMenuItemIds = (menuItems) => {
  let matchingItems = []
  for (let menuItem of menuItems) {
    if (window.location.pathname.indexOf(menuItem.path) === 0) {
      matchingItems.push(menuItem.id)
    }

    if (typeof menuItem.children !== 'undefined') {
      matchingItems = [
        ...matchingItems,
        ...getActivatedMenuItemIds(menuItem.children),
      ]
    }
  }
  return matchingItems
}
