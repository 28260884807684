import { AuthContext } from '@contexts/authContext'
import React, { useContext, lazy, useEffect } from 'react'
import {
  Redirect,
  Route as BaseRoute,
  useLocation,
  useParams,
} from 'react-router-dom'

// handle auth and authorization
const Route = ({ component: Component, ...rest }) => {
  const { authState, loading } = useContext(AuthContext)
  const { state: locState = false } = useLocation()
  const a = useParams()
  useEffect(() => {
    console.log('router', a)
    console.log(locState, authState, loading)
  }, [locState, authState, loading, a])

  return (
    <BaseRoute
      {...rest}
      render={(props) => {
        const { pathname } = props.location
        const isAccountPath = pathname.startsWith('/account') ? true : false
        const isCallback = pathname.startsWith('/idpcallback')
        if (loading) {
          return <></>
        } else {
          if (
            !authState.isSignUp &&
            !authState.isSignIn &&
            !authState.isRegisteredlogin &&
            pathname !== '/account/login' &&
            pathname !== '/account/signup' &&
            pathname !== '/account/forget'
          ) {
            // not logged in so redirect to login page with the return url
            return (
              <Redirect
                to={{
                  pathname: '/account/login',
                  state: {
                    from: locState || isAccountPath ? '/' : props.location,
                  },
                }}
              />
            )
          } else if (
            authState.isSignUp &&
            !authState.isSignIn &&
            !authState.isRegisteredlogin &&
            pathname !== '/account/confirm'
          ) {
            return (
              <Redirect
                to={{
                  pathname: '/account/confirm',
                  state: {
                    from: locState || isAccountPath ? '/' : props.location,
                  },
                }}
              />
            )
          } else if (
            authState.isSignUp &&
            authState.isSignIn &&
            !authState.isRegistered &&
            pathname !== '/account/register'
          ) {
            return (
              <Redirect
                to={{
                  pathname: '/account/register',
                  state: {
                    from: locState || isAccountPath ? '/' : props.location,
                  },
                }}
              />
            )
          }
          if (
            authState.isSignUp &&
            authState.isSignIn &&
            authState.isRegistered &&
            (isAccountPath || isCallback) &&
            pathname !== '/account/logout'
          ) {
            return (
              <Redirect
                to={{ pathname: locState ? locState.from.pathname : '/' }}
              />
            )
          }
        }

        // authorised so return component
        return <Component {...props} />
      }}
    />
  )
}

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  component: () => <Redirect to="/dashboard" />,
  route: Route,
}

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'ホーム',
    icon: 'uil-home-alt',
    header: 'Navigation',
    component: lazy(() => import('@pages/dashboards/Project')),
    route: Route,
  },
]

// 各種機能
const featureAppRoutes = [
  {
    path: '/favorites',
    name: 'お気に入り',
    route: Route,
    icon: 'uil-star',
    component: lazy(() => import('@pages/favorites')),
  },
  {
    path: '/companies',
    name: '会社管理',
    route: Route,
    icon: 'uil-store',
    component: lazy(() => import('@pages/companies')),
  },
  {
    path: '/search/project',
    name: 'プロジェクト検索',
    route: Route,
    icon: 'uil-atm-card',
    component: lazy(() => import('@pages/search/Project')),
  },
  {
    path: '/projects',
    name: 'プロジェクト管理',
    route: Route,
    exact: true,
    icon: 'uil-package',
    component: lazy(() => import('@pages/projects')),
  },
  {
    path: '/contracts',
    name: '契約書管理',
    route: Route,
    icon: 'uil-file-contract-dollar',
    component: lazy(() => import('@pages/contracts')),
  },
  {
    path: '/options',
    name: 'オプション管理',
    component: lazy(() => import('@pages/options')),
    icon: 'uil-ticket',
    route: Route,
  },
]

// 各種機能
const featureHideAppRoutes = [
  // {
  //   path: '/search',
  //   name: '仕事を探す / 人材を探す ',
  //   icon: 'uil-search-plus',
  //   component: lazy(() => import('@pages/search')),
  //   route: Route,
  // },
  // {
  //   path: '/dashboard',
  //   name: '案件作成',
  //   route: Route,
  //   icon: 'uil-store',
  //   component: lazy(() => import('@pages/dashboards/Project')),
  // },
  {
    path: '/projects/create',
    name: 'プロジェクト作成',
    route: Route,
    component: lazy(() => import('@pages/projects/Create')),
  },
  // {
  //   path: '/projects/:id/update',
  //   name: '案件更新',
  //   route: Route,
  //   component: lazy(() => import('@pages/projects/Edit')),
  // },
  {
    path: '/companies/create',
    name: '会社作成',
    route: Route,
    component: lazy(() => import('@pages/companies/Create')),
  },
  //{
  //path: '/companies/:id/edit',
  //name: '会社編集',
  //route: Route,
  //component: lazy(() => import('@pages/companies/Edit')),
  //},
  {
    path: '/projects/:id',
    name: 'プロジェクト詳細',
    route: Route,
    component: lazy(() => import('@pages/projects/Detail')),
  },
  {
    path: '/messages',
    name: 'メッセージ',
    route: Route,
    component: lazy(() => import('@pages/messages')),
  },
  {
    path: '/users/:id',
    name: 'ユーザー詳細',
    route: Route,
    component: lazy(() => import('@pages/users/Detail')),
  },
  {
    path: '/companies/:id',
    name: '会社詳細',
    route: Route,
    component: lazy(() => import('@pages/companies/Detail')),
  },
  {
    path: '/contracts/create',
    name: '契約書作成',
    route: Route,
    component: lazy(() => import('@pages/contracts/Create')),
  },
  // {
  //   path: '/contracts/:id/update',
  //   name: '契約書更新',
  //   route: Route,
  //   roles: ['Admin'],
  //   icon: 'uil-store',
  //   component: lazy(() => import('@pages/contracts/Edit')),
  // },
  // {
  //   path: '/web_meetings',
  //   name: 'webMTG管理',
  //   route: Route,
  //   icon: 'uil-meeting-board',
  //   component: lazy(() => import('@pages/webMeetings')),
  // },
  // {
  //   path: '/web_meetings/create',
  //   name: 'webMTGルーム作成',
  //   route: Route,
  //   icon: 'uil-briefcase',
  //   component: lazy(() => import('@pages/webMeetings/Create')),
  // },
  // {
  //   path: '/web_meetings/:hash/update',
  //   name: 'webMTGルーム更新',
  //   route: Route,
  //   icon: 'uil-briefcase',
  //   component: lazy(() => import('@pages/webMeetings/Edit')),
  // },
]

// auth
const authRoutes = {
  path: '/account',
  name: 'Auth',
  children: [
    {
      path: '/account/login',
      name: 'Login',
      component: lazy(() => import('@pages/auth/Login')),
      route: Route,
    },
    {
      path: '/account/signup',
      name: 'Signup',
      component: lazy(() => import('@pages/auth/Signup')),
      route: Route,
    },
    {
      path: '/account/logout',
      name: 'Logout',
      component: lazy(() => import('@pages/auth/Logout')),
      route: Route,
    },
    {
      path: '/account/register',
      name: 'Register',
      component: lazy(() => import('@pages/auth/Register')),
      route: Route,
    },
    {
      path: '/account/confirm',
      name: 'Confirm',
      component: lazy(() => import('@pages/auth/Confirm')),
      route: Route,
    },
    {
      path: '/account/forget',
      name: 'Forget Password',
      component: lazy(() => import('@pages/auth/ForgetPassword')),
      route: Route,
    },
    {
      path: '/account/idpcallback',
      name: 'Callback',
      component: lazy(() => import('@pages/auth/Callback')),
      route: Route,
    },
  ],
}

// 設定
const settingRoutes = [
  {
    path: '/settings/profile',
    name: 'Profile',
    component: lazy(() => import('@pages/settings/Profile')),
    route: Route,
  },
  // {
  //   path: '/settings/conditions',
  //   name: 'Condition',
  //   component: lazy(() => import('@pages/settings/Condition')),
  //   route: Route,
  // },
  // {
  //   path: '/settings/other',
  //   name: 'Condition',
  //   component: lazy(() => import('@pages/settings/Other')),
  //   route: Route,
  // },
  {
    path: '/account/logout',
    name: 'Logout',
    component: lazy(() => import('@pages/auth/Logout')),
    route: Route,
  },
  // {
  //   path: '/account/register',
  //   name: 'Register',
  //   component: lazy(() => import('@pages/auth/Register')),
  //   route: Route,
  // },
  // {
  //   path: '/account/confirm',
  //   name: 'Confirm',
  //   component: lazy(() => import('@pages/auth/Confirm')),
  //   route: Route,
  // },
  // {
  //   path: '/account/forget-password',
  //   name: 'Forget Password',
  //   component: lazy(() => import('@pages/auth/ForgetPassword')),
  //   route: Route,
  // },
]
// リンク
const linkRoutes = [
  {
    path: '/terms',
    name: 'terms',
    component: lazy(() => import('@pages/terms')),
    route: Route,
  },
  {
    path: '/privacy-policy',
    name: 'Privacy-Policy',
    component: lazy(() => import('@pages/privacyPolicy')),
    route: Route,
  },
  {
    path: '/trade',
    name: 'Trade',
    component: lazy(() => import('@pages/trade')),
    route: Route,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: lazy(() => import('@pages/contact')),
    route: Route,
  },
]

// flatten the list of all nested routes
const flattenRoutes = (routes = []) =>
  routes.reduce((acc, crr) => {
    return typeof crr.children !== 'undefined'
      ? [...acc, crr, ...flattenRoutes(crr.children)]
      : [...acc, crr]
  }, [])

// 全てのルート
const allRoutes = [
  rootRoute,
  ...dashboardRoutes,
  ...featureHideAppRoutes,
  ...featureAppRoutes,
  authRoutes,
  ...settingRoutes,
  ...linkRoutes,
]

// メニューに表示したいルート設定
const navigationDisplayRoutes = [...dashboardRoutes, ...featureAppRoutes]

const allFlattenRoutes = flattenRoutes(allRoutes)

export { allRoutes, allFlattenRoutes, navigationDisplayRoutes }
