import React, { createContext, useEffect, useState } from 'react'
import useRequest from '../hooks/useRequest'
const stub = () => {
  throw new Error('forgot to wrap provider')
}
const initialState = {
  auth: stub,
  isLoggedIn: stub,
  googleSignIn: stub,
  facebookSignIn: stub,
  signIn: stub,
  signUp: stub,
  signOut: stub,
}

export const AuthContext = createContext(initialState)
const { Provider } = AuthContext
// state name
// logout // signup: false, login: false
// signup // signup: true, login: false
// signin // signup: true, login: true
// login  // registered: true
export const AuthProvider = ({ auth: Auth, hub: Hub, children }) => {
  const [authState, setAuthState] = useState({
    isSignUp: false,
    isSignIn: false,
    isRegistered: true,
  })
  const [authData, setAuthData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState(null)
  const [configData, setConfigData] = useState(null)

  const updateAuthState = (type) => {
    switch (type) {
      case 'signin':
        setAuthState({ ...authState, isSignUp: true, isSignIn: true })
        break
      case 'signup':
        setAuthState({
          ...authState,
          isSignUp: true,
          isSignIn: false,
        })
        break
      case 'login':
        setAuthState({
          ...authState,
          isSignUp: true,
          isSignIn: true,
          isRegistered: true,
        })
        break
      case 'logout':
        setAuthState({
          ...authState,
          isSignUp: false,
          isSignIn: false,
          isRegistered: false,
        })
        break
    }
  }

  // Auth functions
  const getAuth = () => Auth.currentAuthenticatedUser()
  const getSession = () => Auth.currentSession()
  const googleSignIn = () => Auth.federatedSignIn({ provider: 'Google' })
  const facebookSignIn = () => Auth.federatedSignIn({ provider: 'Facebook' })
  const userRequest = useRequest('/v1//auth/user', getSession)
  const configRequest = useRequest('/v1/config', getSession)

  const signIn = async (username, pass) => {
    setAuthData({ username })
    await Auth.signIn(username, pass)
  }

  const signUp = ({ username, password, attributes }) =>
    Auth.signUp({ username, password, attributes })

  const signOut = () => Auth.signOut()

  const getUser = async () => {
    try {
      const res = await userRequest.get()
      setUserData(res.data)
      updateAuthState('login')
      return res
    } catch (err) {
      console.log(err)
    }
    return false
  }

  // 各機能共通 & 変更性があるマスタデータを取得
  const getConfig = async () => {
    try {
      // 設定情報取得処理実行
      const res = await configRequest.get()

      // 設定情報オブジェクト初期化
      const config = {
        chargeOptions: {
          label: 'チャージオプション',
          options: res.data.chargeOptions
            ? res.data.chargeOptions.map((v) => {
                return {
                  label: v.name,
                  value: v.id,
                }
              })
            : [],
        },
        chargePlans: {
          label: 'チャージ方法',
          options: res.data.chargePlans
            ? res.data.chargePlans.map((v) => {
                return {
                  label: v.name,
                  value: v.id,
                }
              })
            : [],
        },
        companyTags: {
          label: '企業タグ',
          options: res.data.companyTags
            ? res.data.companyTags.map((v) => {
                return {
                  label: v.name,
                  value: v.id,
                }
              })
            : [],
        },
        payments: {
          label: '決済方法',
          options: res.data.payments
            ? res.data.payments.map((v) => {
                return {
                  label: v.name,
                  value: v.id,
                }
              })
            : [],
        },
        categories: [],
        locations: [],
      }

      // ------------------------------------------
      // 特殊セレクター情報設定: categories
      // ------------------------------------------
      if (res.data.categories) {
        // 親カテゴリーデータを設定
        config.categories = res.data.categories
          .filter((v) => {
            return !v.parent.id ? true : false
          })
          .map((v) => {
            return {
              label: v.name,
              value: v.id,
              options: [],
            }
          })

        // 子カテゴリーデータを設定
        for (let i = 0; i < res.data.categories.length; i++) {
          // 親カテゴリーが存在しないカテゴリーは処理スキップ
          if (!res.data.categories[i].parent.id) continue

          // 親カテゴリーの配列キーを取得
          const parentIndex = config.categories.findIndex(
            (v) => v.value === res.data.categories[i].parent.id
          )

          // 親カテゴリーの配列キーが存在しない場合は処理スキップ (基本はありえないがAPI側のシステムバグ対策として
          if (parentIndex === -1) continue

          // 親カテゴリーデータに小カテゴリーデータを設定
          config.categories[parentIndex].options.push({
            label: res.data.categories[i].name,
            value: res.data.categories[i].id,
          })
        }
      }

      // ------------------------------------------
      // 特殊セレクター情報設定: locations
      // ------------------------------------------
      if (res.data.locations) {
        // 親ロケーションーデータを設定
        config.locations = res.data.locations
          .filter((v) => {
            return !v.parent.id ? true : false
          })
          .map((v) => {
            return {
              label: v.name,
              value: v.id,
              options: [],
            }
          })

        // 子ロケーションデータを設定
        for (let i = 0; i < res.data.locations.length; i++) {
          // 親ロケーションが存在しないロケーションは処理スキップ
          if (!res.data.locations[i].parent.id) continue

          // 親ロケーションの配列キーを取得
          const parentIndex = config.locations.findIndex(
            (v) => v.value === res.data.locations[i].parent.id
          )

          // 親ロケーションの配列キーが存在しない場合は処理スキップ (基本はありえないがAPI側のシステムバグ対策として
          if (parentIndex === -1) continue

          // 親ロケーションデータに小ロケーションデータを設定
          config.locations[parentIndex].options.push({
            label: res.data.locations[i].name,
            value: res.data.locations[i].id,
          })
        }
      }

      return config
    } catch (err) {
      console.log(err)
    }
    return false
  }

  const register = async (payload) => {
    try {
      await userRequest.post(payload)
      await getUser()
    } catch (err) {
      console.log(err)
    }
  }
  const resendCode = (username) => Auth.resendSignUp(username)
  const verifyCode = async (code: number) => {
    await Auth.confirmSignUp(authData.username, code)

    // ログイン画面に遷移させるために認証状態を更新 ( amplifyのサインインイベントにconfirmSinupがないみたいなので
    updateAuthState('logout')
    setUserData(null)
    setAuthData(null)
    setConfigData(null)

    // コード認証画面に遷移した時、amplify上はログイン状態になっていない
    // await getAuth()
  }
  const forgotPassword = (username) => Auth.forgotPassword(username)
  const forgotPasswordCode = (username, code, newPassword) =>
    Auth.forgotPasswordSubmit(username, code, newPassword)

  //initialize
  const init = async () => {
    try {
      setLoading(true)
      const data = await getAuth()
      updateAuthState('signin')
      setAuthData(data)

      console.log('ログイン成功')

      // 全体設定を取得
      const config = await getConfig()
      setConfigData(config)
    } catch (err) {
      console.log('init error', err)
    } finally {
      setLoading(false)
    }
  }

  //test
  useEffect(() => {
    console.log('state tracking in Auth context', authState, authData)
  }, [authState, authData])

  // event listener
  useEffect(() => {
    const hubCallback = async ({ payload: { event, data } }) => {
      setLoading(true)
      switch (event) {
        case 'signIn':
          updateAuthState('signin')
          setAuthData(data)
          await getUser()
          break
        case 'signUp':
          updateAuthState('signup')
          setAuthData(data.user)
          // setAuthData(data)
          break
        case 'signOut':
          updateAuthState('logout')
          setUserData(null)
          setAuthData(null)
          break
        case 'signIn_failure':
          if (data.code === 'UserNotConfirmedException') {
            updateAuthState('signup')
          } else {
            setAuthData(null)
            setUserData(null)
          }
          break
        case 'tokenRefresh':
        case 'tokenRefresh_failure':
          if (typeof data == 'undefined' || typeof data.code == 'undefined') {
            console.log('--------------------------------')
            console.log('authContext tokenRefresh, tokenRefresh_failure')
            console.log(data)
            console.log('--------------------------------')
          } else if (data.code === 'UserNotConfirmedException') {
            updateAuthState('signup')
            setUserData(null)
          } else if (data.code === 'UserNotFoundException') {
            updateAuthState('logout')
            setAuthData(null)
            setUserData(null)
          }
          break
      }
      setLoading(false)
    }
    Hub.listen('auth', hubCallback)
    init()
    return () => Hub.remove('auth', hubCallback)
  }, [])

  return (
    <Provider
      value={{
        loading,
        authState,
        authData,
        userData,
        configData,
        googleSignIn,
        facebookSignIn,
        signIn,
        signUp,
        signOut,
        verifyCode,
        resendCode,
        getUser,
        getConfig,
        getSession,
        register,
        forgotPassword,
        forgotPasswordCode,
      }}
    >
      {children}
    </Provider>
  )
}
