import React, { createContext, useEffect, useState } from 'react'
import * as layoutConstants from '@constants/layout'
const stub = () => {
  throw new Error('forgot to wrap provider')
}

const initialState = {
  changeLayout: stub,
  changeLayoutWidth: stub,
  changeSidebarTheme: stub,
  changeSidebarType: stub,
  toggleRightSideBar: stub,
  showRightSideBar: stub,
  hideRightSidebar: stub,
  layoutState: null,
}
export const LayoutContext = createContext(initialState)
const { Provider } = LayoutContext

const useLayout = ({
  layoutType: initLayoutType,
  layoutWidth: initLayoutWidth,
  leftSideBarTheme: initLeftTheme,
  leftSideBarType: initLeftType,
  isShowRightSidebar: initIsShowRight,
}) => {
  const [layoutType, setLayoutType] = useState(initLayoutType)
  const [layoutWidth, setLayoutWidth] = useState(initLayoutWidth)
  const [leftSideBarTheme, setLeftSideBarTheme] = useState(initLeftTheme)
  const [leftSideBarType, setLeftSideBarType] = useState(initLeftType)
  const [isShowRightSidebar, setIsShowRightSidebar] = useState(initIsShowRight)
  const updateState = (args = {}) => {
    Object.keys(args).forEach((key) => {
      switch (key) {
        case 'layoutType':
          setLayoutType(args[key])
          break
        case 'layoutWidth':
          setLayoutWidth(args[key])
          break
        case 'leftSideBarTheme':
          setLeftSideBarTheme(args[key])
          break
        case 'leftSideBarType':
          setLeftSideBarType(args[key])
          break
        case 'isShowRightSidebar':
          setIsShowRightSidebar(args[key])
          break
      }
    })
  }
  return [
    {
      layoutType,
      layoutWidth,
      leftSideBarTheme,
      leftSideBarType,
      isShowRightSidebar,
    },
    updateState,
  ]
}
export const LayoutProvider = ({ children }) => {
  const [
    {
      layoutType,
      layoutWidth,
      leftSideBarTheme,
      leftSideBarType,
      isShowRightSidebar,
    },
    setLayoutState,
  ] = useLayout({
    layoutType: layoutConstants.LAYOUT_VERTICAL,
    layoutWidth: layoutConstants.LAYOUT_WIDTH_FLUID,
    leftSideBarTheme: layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT,
    leftSideBarType: layoutConstants.LEFT_SIDEBAR_TYPE_FIXED,
    isShowRightSidebar: false,
  })
  const changeBodyAttribute = (attribute, value) => {
    if (document.body) document.body.setAttribute(attribute, value)
    return true
  }
  const manageBodyClass = (cssClass, action) => {
    switch (action) {
      case 'add':
        if (document.body) document.body.classList.add(cssClass)
        break
      case 'remove':
        if (document.body) document.body.classList.remove(cssClass)
        break
      default:
        if (document.body) document.body.classList.toggle(cssClass)
        break
    }
    return true
  }

  useEffect(() => {
    layoutType && changeBodyAttribute('data-layout', layoutType)
    if (layoutType === layoutConstants.LAYOUT_VERTICAL) {
      setLayoutState({
        leftSideBarTheme: layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT,
        leftSideBarType: layoutConstants.LEFT_SIDEBAR_TYPE_FIXED,
      })
    }

    if (layoutType === layoutConstants.LAYOUT_HORIZONTAL) {
      setLayoutState({
        leftSideBarTheme: layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT,
        leftSideBarType: layoutConstants.LEFT_SIDEBAR_TYPE_FIXED,
      })
    }

    if (layoutType === layoutConstants.LAYOUT_DETACHED) {
      setLayoutState({
        layoutWidth: layoutConstants.LAYOUT_WIDTH_FLUID,
        leftSideBarTheme: layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT,
        leftSideBarType: layoutConstants.LEFT_SIDEBAR_TYPE_SCROLLABLE,
      })
    }
  }, [layoutType])

  useEffect(() => {
    layoutWidth && changeBodyAttribute('data-layout-mode', layoutWidth)
  }, [layoutWidth])

  useEffect(() => {
    leftSideBarTheme &&
      changeBodyAttribute('data-leftbar-theme', leftSideBarTheme)
  }, [leftSideBarTheme])

  useEffect(() => {
    leftSideBarType &&
      changeBodyAttribute('data-leftbar-compact-mode', leftSideBarType)
  }, [leftSideBarType])

  useEffect(() => {
    manageBodyClass('right-bar-enabled', isShowRightSidebar ? 'add' : 'remove')
  }, [isShowRightSidebar])
  const changeLayout = (layout) => {
    setLayoutState({
      layoutType: layout,
    })
  }
  const changeLayoutWidth = (width) => {
    setLayoutState({
      layoutWidth: width,
    })
  }
  const changeSidebarTheme = (theme) => {
    setLayoutState({
      leftSideBarTheme: theme,
    })
  }
  const changeSidebarType = (type) => {
    setLayoutState({ leftSideBarType: type })
  }
  const toggleRightSidebar = () => {
    setLayoutState({
      isShowRightSidebar: !isShowRightSidebar,
    })
  }
  const showRightSidebar = () => {
    setLayoutState({
      isShowRightSidebar: true,
    })
  }
  const hideRightSidebar = () => {
    setLayoutState({
      isShowRightSidebar: false,
    })
  }
  return (
    <Provider
      value={{
        changeLayout,
        changeLayoutWidth,
        changeSidebarTheme,
        changeSidebarType,
        toggleRightSidebar,
        showRightSidebar,
        hideRightSidebar,
        layoutState: {
          layoutType,
          layoutWidth,
          leftSideBarTheme,
          leftSideBarType,
          isShowRightSidebar,
        },
      }}
    >
      {children}
    </Provider>
  )
}
