import { mapKeysCamelCase, mapKeysSnakeCase } from '../mapKeyConvert'

export const requestBuilder = (session, option) => {
  if (!session) {
    throw Error('not auth')
  }

  if (option.data) {
    option.data = mapKeysSnakeCase(option.data)
  }
  if (option.data) {
    option.params = mapKeysSnakeCase(option.params)
  }
  option.headers = {
    ...option.headers,
    Authorization: session.idToken.jwtToken,
    'Content-Type': 'application/json',
  }

  return option
}

export const responseBuilder = (res) => {
  return {
    status: res.status,
    headers: res.headers,
    data: mapKeysCamelCase(res.data),
  }
}
