import {
  useState,
  useContext,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react'

import { AxiosContext } from '@contexts/axiosContext'
import { requestBuilder, responseBuilder } from '@builders/reqBuilder'

import { dataFetcher, resourceCache } from '@helpers/requests'
import { AuthContext } from '@contexts/authContext'

//requestBuilders
const useRequest = (url, getSessionFn = null) => {
  // global axios instance
  const { instance: axiosInstance } = useContext(AxiosContext)
  if (!axiosInstance) throw new Error('axios provider required')
  const { getSession } = useContext(AuthContext)

  // request
  const request = async (reqConfig) => {
    const session = getSessionFn ? await getSessionFn() : await getSession()
    return axiosInstance(requestBuilder(session, reqConfig)).then((res) =>
      responseBuilder(res)
    )
  }
  const paralellRequest = (configArray) => {
    return Promise.all(configArray.map((reqConf) => request(reqConf)))
  }

  // requestBuilder
  const requester = (method) => (data, targetUrl) => {
    const optBuilder = (args, newUrl) => {
      // 引数にURLが指定された場合は、引数のURLを優先使用
      if (newUrl) {
        return Array.isArray(args)
          ? args.map((arg) =>
              method === 'GET'
                ? { method, params: arg, url: newUrl }
                : { method, data: arg, url: newUrl }
            )
          : method === 'GET'
          ? { method, params: args, url: newUrl }
          : { method, data: args, url: newUrl }
      } else {
        return Array.isArray(args)
          ? args.map((arg) =>
              method === 'GET'
                ? { method, params: arg, url }
                : { method, data: arg, url }
            )
          : method === 'GET'
          ? { method, params: args, url }
          : { method, data: args, url }
      }
    }

    return Array.isArray(data)
      ? paralellRequest(optBuilder(data, targetUrl))
      : request(optBuilder(data, targetUrl))
  }

  const fetcherWithCache =
    (method) =>
    (initParams = {}, isForce) => {
      const optBuilder = (params) =>
        Array.isArray(params)
          ? params.map((params) => ({ method, params, url }))
          : { method, params, url }

      const [params, setParams] = useState(initParams)
      const [getFn, setGetFn] = useState(() =>
        Array.isArray(initParams) ? paralellRequest : request
      )

      const [getter, updater] = useAsyncResource(
        getFn,
        optBuilder(params),
        isForce
      )

      useEffect(() => {
        updater(optBuilder(params))
        setGetFn(() => (Array.isArray(params) ? paralellRequest : request))
      }, [params])

      const refetch = () => {
        updater(optBuilder(params))
      }

      return [getter, setParams, refetch]
    }

  return {
    get: requester('GET'),
    asyncGet: fetcherWithCache('GET'),
    put: requester('PUT'),
    post: requester('POST'),
    delete: requester('DELETE'),
    axiosPost: requester('POST'),
    axiosDelete: requester('DELETE'),
  }
}

// const existParams = (params) => {
//   return params.length
// }

// const isFnNoArgs = (fn, params) => {
//   return (
//     !fn.length &&
//     params.length === 1 &&
//     Array.isArray(params[0]) &&
//     params[0].length === 0
//   )
// }

export const useAsyncResource = (apiFunction, option, isForce) => {
  // keep the data reader inside a mutable object ref
  // always initialize with a lazy data reader, as it can be overwritten by the useMemo immediately
  const fetcherRef = useRef(() => undefined)
  const clearCache = (reqFn, option) => {
    resourceCache(reqFn).delete(option)
  }

  const fetcherBuilder = (apiFunction, newOpt) => {
    isForce && clearCache(apiFunction, option)
    return dataFetcher(apiFunction, newOpt)
  }

  // like useEffect, but runs immediately
  useMemo(() => {
    fetcherRef.current = fetcherBuilder(apiFunction, option)
  }, [apiFunction, option])

  // state to force re-render
  const [, forceRender] = useState(0)
  const updaterFn = (newOpt) => {
    // update the object ref
    fetcherRef.current = fetcherBuilder(apiFunction, newOpt)
    // update state to force a re-render
    forceRender((ct) => 1 - ct)
  }

  return [fetcherRef.current, updaterFn]
}
export default useRequest
