export default class ResourceException extends Error {
  constructor(message, code) {
    super(message)
    this.code = code

    Object.defineProperty(this, 'name', {
      configurable: true,
      enumerable: false,
      value: this.constructor.name,
      writable: true,
    })

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ResourceException)
    }
  }
}
