const isLocalhost = window
  ? Boolean(
      window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    )
  : false

export const AmplifyConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'ap-northeast-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,

    // OPTIONAL - customized storage object
    storage: window.localStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_SRP_AUTH',

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: process.env.REACT_APP_AUTH_DOMAIN,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: isLocalhost
        ? 'http://localhost:3000/account/idpcallback'
        : process.env.REACT_APP_URL + '/account/idpcallback',
      redirectSignOut: isLocalhost
        ? 'http://localhost:3000/account/logout'
        : process.env.REACT_APP_URL + '/account/logout',
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
}
