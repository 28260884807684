import hash from 'object-hash'

// global cache
const caches = new Map()

export const resourceCache = (apiFn) => {
  // initialize
  if (!caches.has(apiFn)) {
    // weak mapのほうがいいかも
    caches.set(apiFn, new Map())
  }

  // get the cache
  const apiCache = caches.get(apiFn)

  // chacheを返す
  return {
    // getter
    get(...params) {
      return apiCache.get(hash(params))
    },
    // setter
    set(dataFn, ...params) {
      return apiCache.set(hash(params), dataFn)
    },
    // deletes the cached(paramに対応したやつだけ)
    delete(...params) {
      return apiCache.delete(hash(params))
    },
    // clears the entire cache(すべて削除)
    clear() {
      caches.delete(apiFn)
      return apiCache.clear()
    },
  }
}
