import React, { useContext } from 'react'
import { Switch, useLocation } from 'react-router-dom'
import Loadable from 'react-loadable'

import { allFlattenRoutes as routes } from './index'
//import { isUserAuthenticated } from '@helpers/authUtils'
import * as layoutConstants from '@constants/layout'
//import { AuthContext } from 'src/helpers/contexts/authContext'
import { LayoutContext } from '@contexts/layoutContext'

// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>

// All layouts/containers
const AuthLayout = Loadable({
  loader: () => import('@layouts/Auth'),
  render(loaded, props) {
    let Component = loaded.default
    return <Component {...props} />
  },
  loading,
})

const VerticalLayout = Loadable({
  loader: () => import('@layouts/Vertical'),
  render(loaded, props) {
    let Component = loaded.default
    return <Component {...props} />
  },
  loading,
})

const HorizontalLayout = Loadable({
  loader: () => import('@layouts/Horizontal'),
  render(loaded, props) {
    let Component = loaded.default
    return <Component {...props} />
  },
  loading,
})

const DetachedLayout = Loadable({
  loader: () => import('@layouts/Detached'),
  render(loaded, props) {
    let Component = loaded.default
    return <Component {...props} />
  },
  loading,
})

const Layout = ({ isAuth, layoutType, children, ...rest }) => {
  const { LAYOUT_HORIZONTAL, LAYOUT_DETACHED } = layoutConstants
  const Layout =
    layoutType === LAYOUT_HORIZONTAL
      ? HorizontalLayout
      : layoutType === LAYOUT_DETACHED
      ? DetachedLayout
      : VerticalLayout
  return (
    <>
      {isAuth ? (
        <AuthLayout {...rest}>{children}</AuthLayout>
      ) : (
        <Layout {...rest}>{children}</Layout>
      )}
    </>
  )
}
const App = (props) => {
  const location = useLocation()

  const {
    layoutState: { layoutType },
  } = useContext(LayoutContext)

  // returns the layout
  // const getLayout = (layoutType) => {
  //   if (location.pathname.startsWith('/account')) return AuthLayout

  //   let layoutCls = VerticalLayout
  //   console.log('getlayout')

  //   switch (layoutType) {
  //     case layoutConstants.LAYOUT_HORIZONTAL:
  //       layoutCls = HorizontalLayout
  //       break
  //     case layoutConstants.LAYOUT_DETACHED:
  //       layoutCls = DetachedLayout
  //       break
  //     default:
  //       layoutCls = VerticalLayout
  //       break
  //   }
  //   return layoutCls
  // }

  // rendering the router with layout
  // console.log('layout', location, layoutType)
  return (
    <Layout
      isAuth={location.pathname.startsWith('/account')}
      layoutType={layoutType}
      {...props}
    >
      <Switch>
        {routes.map((route, index) => {
          return (
            !route.children && (
              <route.route
                key={index}
                path={route.path}
                roles={route.roles}
                exact={route.exact}
                component={route.component}
              ></route.route>
            )
          )
        })}
      </Switch>
    </Layout>
  )
}

export default App
