// setup fake backend
import { configureFakeBackend } from '@helpers'
import Routes from '@routes/Routes'
import flatpickr from 'flatpickr'
import { Japanese } from 'flatpickr/dist/l10n/ja.js'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
// Themes
import './assets/scss/Saas.scss'

flatpickr.localize(Japanese)

// For Dark import Saas-Dark.scss import './assets/scss/Saas-Dark.scss';

// For Modern import Modern.scss
// import './assets/scss/Modern.scss';
// For modern dakr import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative demo import Modern.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Modern.scss
// import './assets/scss/Creative-Dark.scss';

// configure fake backend
//configureFakeBackend()

/**
 * Main app component
 */
class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    )
  }
}

export default App
