import React from 'react'
import { Auth, Hub } from '@config/amplify'
import { AuthProvider } from '@contexts/authContext'
import { AxiosProvider } from '@contexts/axiosContext'
import { LayoutProvider } from '@contexts/layoutContext'
import { PaymentProvider } from '@contexts/paymentContext'
import { ChatProvider } from '@contexts/chatContext'
import { AppMenuProvider } from '@contexts/appMenuContext'
import axios from 'axios'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8080',
  responseType: 'json',
  headers: {
    // api_key: 'special-key',
    Accept: 'Application/json',
  },
})

const rootNode = document.getElementById('root')

ReactDOM.render(
  <AxiosProvider instance={axiosInstance}>
    <AuthProvider auth={Auth} hub={Hub}>
      <LayoutProvider>
        <ChatProvider>
          <PaymentProvider>
            <AppMenuProvider>
              <App />
            </AppMenuProvider>
          </PaymentProvider>
        </ChatProvider>
      </LayoutProvider>
    </AuthProvider>
  </AxiosProvider>,
  rootNode
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
